import React, { Component } from "react";
import Fade from "react-reveal";

class Footer extends Component {
  render() {
    if (!this.props.data) return null;
    return (
      <footer>
        <div className="row">
          <div className="twelve columns">
            <ul className="copyright">
              <li>&copy; Copyright 2022 北京有这么一家科技有限公司</li>
              <li>
                <a target="_blank" href="https://beian.miit.gov.cn">
                  京ICP备2022027917号-2
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402013219">
                  <img alt="" src="images/icp.png" height="20" width="20"/>
                  &nbsp;
                  京公网安备 11011402013219号
                </a>
              </li>
            </ul>
          </div>
          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="fa fa-arrow-up"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
