import React, { Component } from "react";
import Fade from "react-reveal";

class Download extends Component {
    render() {
        if (!this.props.data) return null;
        const {apple, android} = this.props.data;

        return (
            <section id="download">
                <div className="downloadDiv">
                    <p className="title">
                        免费下载使用
                    </p>
                    <p className="subTitle">
                        一念适用于 iPhone，Android
                    </p>
                    <Fade bottom duration={1000}>
                        <ul className="social">
                            <a href={apple} className="button btn project-btn">
                                <i className="fa fa-2x fa-apple"></i>&nbsp;&nbsp;App Store
                            </a>
                            <a href={android} className="button btn github-btn">
                                <i className="fa fa-2x fa-android"></i>&nbsp;&nbsp;下载
                            </a>
                        </ul>
                    </Fade>
                    <div className="privacy">
                        <a href="privacy_zh.html">
                            隐私协议&nbsp;&nbsp;
                        </a>
                        <a href="service_zh.html">
                            |&nbsp;&nbsp;服务条款
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Download;