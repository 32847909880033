import React, { Component } from "react";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Banner from "./Components/Banner";
import Fuction from "./Components/Fuction";
import PhoneLeft from "./Components/PhoneLeft";
import PhoneRight from "./Components/PhoneRight";
import Download from "./Components/Download";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {}
    };
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    const main = this.state.resumeData.main;
    return (
      <div className="App">
        <Header data={main} />
        <Banner data={main} />
        <Fuction data={main} />
        <PhoneLeft data={main && main.phones[0]}/>
        <PhoneRight data={main && main.phones[1]}/>
        <PhoneLeft data={main && main.phones[2]}/>
        <PhoneRight data={main && main.phones[3]}/>
        <PhoneLeft data={main && main.phones[4]}/>
        <Download data={main} />
        <Footer data={main} />
      </div>
    );
  }
}

export default App;
