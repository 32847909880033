import React, { Component } from "react";
import Fade from "react-reveal";

class Fuction extends Component {
    render() {
        if (!this.props.data) return null;
        return (
            <section id="fuction">
                <div className="functionDiv">
                    <div className="insideDiv">
                        <h2 >主要功能</h2>
                        <h3>集白噪音、呼吸冥想、番茄时钟等正念工具于一体。畅听海量白噪音，拥抱良好睡眠。呼吸冥想，在繁忙的工作生活中，调整放松。 使用番茄工作法，保持专注，告别拖延。</h3>
                        <Fade right duration={1000}>
                            <div className="infoContent">
                                <div className="infos">
                                    <p className="title">
                                        白噪音
                                    </p>
                                    <p className="info">
                                        海量白噪音
                                    </p>
                                    <p className="info">
                                        自然声音/场景全覆盖
                                    </p>
                                </div>
                                <div className="infos infosRight">
                                    <p className="title">
                                        冥想
                                    </p>
                                    <p className="info">
                                        多种冥想模式
                                    </p>
                                    <p className="info">
                                        不同时段 不同效果
                                    </p>
                                </div>
                            </div>
                        </Fade>
                        <Fade left duration={1000}>
                            <div className="infoContent">
                                <div className="infos">
                                    <p className="title">
                                        番茄时钟
                                    </p>
                                    <p className="info">
                                        自定义时段和背景
                                    </p>
                                    <p className="info">
                                        全屏时钟/保持专注
                                    </p>
                                </div>
                                <div className="infos infosRight">
                                    <p className="title">
                                        统计
                                    </p>
                                    <p className="info">
                                        精准统计
                                    </p>
                                    <p className="info">
                                        每日每周/时时回顾
                                    </p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        );
    }
}

export default Fuction;