import React, { Component } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";

class PhoneLeft extends Component {
    render() {
        if (!this.props.data) return null;
        const {imgPath, title, content} = this.props.data;
        return (
            <section id="phone">
                <div className="phoneDiv phoneColored">
                    <Fade left duration={2000}>
                        <div className="photoContent">
                            <Zmage className="img" src={imgPath}/>
                        </div>
                    </Fade>
                    <div className="textContent">
                        <p className="title titleLeft">
                            {title}
                        </p>
                        <p className="content">
                            {content}
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default PhoneLeft;