import React, { Component } from "react";
import Fade from "react-reveal";

class Banner extends Component {
    render() {
        if (!this.props.data) return null;
        const description = this.props.data.description;
        return (
            <section id="banner">
                <div className="mistBanner">
                    <h2 >{description}</h2>
                    <img className="icon" alt="" src="images/1.png"/>
                    <img className="icon" alt="" src="images/2.png"/>
                    <img className="icon" alt="" src="images/3.png"/>
                    <Fade bottom duration={1000}>
                        <img className="img" alt="" src="images/banner.png"/>
                    </Fade>
                </div>
            </section>
        );
    }
}

export default Banner;